<template>
  <div>
    <ProductPage v-if="masterProductId && shouldUsePLPUrls" />
    <ProductListPage v-else />
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import { useMasterPP } from '../composables/useMasterPP'
import { useMasterPPFeatureFlags } from '../composables/useMasterPPFeatureFlags'

const ProductPage = defineAsyncComponent(
  () => import('~/scopes/product/pages/Product.vue'),
)
const ProductListPage = defineAsyncComponent(
  () => import('~/scopes/product-list/pages/ProductListPage.vue'),
)

const { shouldUsePLPUrls } = useMasterPPFeatureFlags()

const { masterProductId } = useMasterPP()
</script>
