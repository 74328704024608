export const PP_IDS_BY_PLP_ID = {
  'c8550be7-e02e-41b1-a5bc-b2e37b2307a7': [
    '129106f7-e7db-4fbf-b7dd-bc32d9b03816',
    '2defb1fb-7c30-435f-a1ff-ab879a6a0ab9',
    '3216e0c7-ba9a-43fa-9d42-0912eb92875c',
    '32e68609-2d7d-4581-a2fa-62918ee1ef45',
    '245a1bee-5c59-4d02-9d94-533ffb6c4f00',
    '3d389f72-3acb-4f8c-b24c-4d99077772c9',
    '4775d225-bddf-44a1-8e86-e358e0706502',
    '0134cb5e-5cc4-4b8f-80ac-70108a8bf7c0',
    '537c3cd3-69ab-41f0-9384-8c4f8e3fb1b5',
    'fc6cf432-bfd9-4ed3-a645-d99dcdf6df7c',
    'd828a2aa-5eed-4c44-96eb-47b939b7891a',
    'e95f1314-66c4-4caf-b41b-f47b25a2b259',
    '71192399-bfe2-428b-ad1e-8255e5025141',
    '8fb257cd-491d-458f-95ea-c02eba4126d0',
  ],
  '2c14d24a-9169-4128-b03b-0a99e454d9b7': [
    '0b0d67a6-528f-4216-ae75-534782735c1f',
    '10c62c1d-a868-4fdb-8737-7af10bbe238d',
    '2fbf8833-2197-43f3-b6b8-f9238e32f26f',
    '30d8b7ed-da1a-482b-849f-50ef2cc3e6d2',
    '16a080a4-28cc-4aa2-8d48-58ec22cfabab',
    '2a88c048-5653-49e7-8832-c33122029931',
    '3fddd5ae-fdcf-4eff-87bc-5e8e33757d0c',
    '414f2c52-5159-4cc5-8c80-fe94f1f0dd52',
    '00317ead-57b7-4bc9-beae-a6234b019139',
    '03bdc251-87b4-4e80-99a4-1d4f699a4163',
    '04b00293-ab04-481c-bd6d-5e2c5401617c',
    '9f0cbc03-215b-4db7-835a-fe3035318d18',
    'aff0b232-be15-4e88-b486-6648394ee7c1',
    'b7c6e12d-0807-46d3-89f8-34f29fa69358',
    'a94ea247-a9df-44d3-962e-4c4010640fab',
    'c292ebbf-61c2-454f-9548-c9c151db5257',
    'c52aadea-3fb9-42d0-af68-d35662302754',
    'cf863187-d88d-4473-a8c7-fd85b8f4fae2',
    'caf90fc4-92bc-4bf4-a964-a50f1456dd18',
    'd43d4d9f-c195-4e07-a17a-55f169fa5d43',
    'db4b74b5-2a47-49c3-8e4b-cdf826c54efe',
    'dc83aaf4-157f-4572-a48d-8abbab4473e9',
    'fa0b419c-f920-4e16-837f-d17e44d94475',
    'fb5a096f-6221-4e06-8f6c-45fe356f7dba',
    'fff01cca-1887-4142-ab9d-5ffdf1ac1ba1',
    '516f1ace-8b02-4cc3-a3a6-15f59dc78e0e',
    '6adf8f34-cd06-4915-a4fd-ac7fe400fa31',
    '6e7e2877-e9dd-42e8-9cc6-0b86c428134b',
    '735af308-48ef-4c83-bb93-d531965806d2',
    '7fc56758-c465-4c8c-b28c-efe912755a37',
    '84208cde-8df5-42d3-9695-a28d0d3ad203',
    '942d5e61-3ae1-4268-a7d6-d491fc67bbd3',
  ],
  'fa83a4e3-3865-4f24-bb0e-6500ba4fec33': [
    'd173b337-9318-4b5f-a885-20ec1d227fcf',
    'd73b7a48-d424-40f4-86ce-8e7975e90fa5',
    'ddb9ed29-5ce4-4e36-a920-a122578ad617',
    '7f5bdd13-8f4b-49ee-a94a-8b578deb328d',
    '15f024ec-312f-4711-9be5-32da1a785bcf',
    '4f4d578f-db15-44ac-b355-dc802c683750',
    '514537ae-1ee4-4353-adf3-4b5bc0896e79',
    '52c8a315-1246-4782-9414-32c192f5719d',
    '5c25e285-47cf-483e-9e05-f36f5d9e4eef',
    'e55d41fb-2b9b-4a57-83a9-5c557cb04fbc',
    'e646435a-0dbc-4def-8150-bcae4471dcb3',
    'eaa215ad-616b-46e1-b220-2b5a9042886b',
    'ec59a99a-dd17-468a-b5aa-fbbddd1c87b5',
    'ffa54805-4d46-4fa5-9e55-589604fff2ae',
    '8bd1aaa2-0950-482e-86eb-540e212fdabb',
    '1dc58f73-0074-40b6-be47-2312bb4b8a85',
    '212ce8fd-97ca-4b50-9378-f1b4b497dfee',
    '06832958-15c7-4784-83c3-5d768671079c',
    '2d99b692-a008-4345-8196-fea4cf5e4096',
    '2e721825-ad6c-41a5-bc2b-47c8a2b139d0',
    '9c632492-fbc7-41ba-b39c-fd6baecf578f',
    'a74f3ddb-c9ea-4d55-adb5-1108dd8fedc2',
    'a7e91236-b62d-43f3-bf7d-42949443fc74',
    'a4d9f7f3-2cab-4db0-8fe6-20b676761785',
    'a58bedb1-ef9c-46bb-bb7b-2406c0fc0089',
    'ad6dc074-aa15-4f0a-bc2f-a4211fee9aec',
    'aeca3f0b-6654-441d-9efc-1214b08e8c3a',
    'b40e2550-876f-4b81-b7b3-491b9534b1c4',
    '0ef61aea-3229-410a-9fa3-f0f992bf7b5f',
    'cff5b372-be5c-4438-ba08-e44eddc96b9c',
  ],
}

export const PLP_SLUGS_BY_PLP_ID = {
  'fa83a4e3-3865-4f24-bb0e-6500ba4fec33': {
    'it-IT': 'iphone-16',
    'pt-PT': 'iphone-16',
    'fr-FR': 'iphone-16',
  },
}
