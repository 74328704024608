import { useRoute, useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import type { Locale } from '@backmarket/http-api'
import { toRfc4646Locale } from '@backmarket/utils/string/toRfc4646Locale'

import { ROUTES as PRODUCT_LIST_ROUTES } from '~/scopes/product-list/route-names'

import {
  PLP_SLUGS_BY_PLP_ID as PREPROD_PLP_SLUGS_BY_PLP_ID,
  PP_IDS_BY_PLP_ID as PREPROD_PP_IDS_BY_PLP_ID,
} from '../constants/data.preprod'
import {
  PLP_SLUGS_BY_PLP_ID as PROD_PLP_SLUGS_BY_PLP_ID,
  PP_IDS_BY_PLP_ID as PROD_PP_IDS_BY_PLP_ID,
} from '../constants/data.prod'

export function useMasterPP() {
  const config = useRuntimeConfig().public
  const route = useRoute()

  const usePreprodApi =
    config.ENVIRONMENT?.startsWith('preprod-') ||
    config.ENVIRONMENT?.startsWith('staging-') ||
    config.ENVIRONMENT === 'local'

  const PP_IDS_BY_PLP_ID = computed<Record<string, string[]>>(() =>
    usePreprodApi ? PREPROD_PP_IDS_BY_PLP_ID : PROD_PP_IDS_BY_PLP_ID,
  )
  const PLP_SLUGS_BY_PLP_ID = computed<Record<string, Record<string, string>>>(
    () =>
      usePreprodApi ? PREPROD_PLP_SLUGS_BY_PLP_ID : PROD_PLP_SLUGS_BY_PLP_ID,
  )

  const PLP_ID_BY_PP_IDS = computed(() => {
    const result = new Map<string, string>([])

    Object.entries(PP_IDS_BY_PLP_ID.value).forEach(([plpId, productIds]) => {
      productIds.forEach((productId: string) => {
        result.set(productId, plpId)
      })
    })

    return result
  })

  function getProductId(plpId: string) {
    return PP_IDS_BY_PLP_ID.value[plpId]?.[0] || null
  }
  function getPlpId(productId: string) {
    return PLP_ID_BY_PP_IDS.value.get(productId) || null
  }

  function getPlpSlug(plpId: string, locale: Locale) {
    return (
      PLP_SLUGS_BY_PLP_ID.value[plpId]?.[toRfc4646Locale(locale)] || 'undefined'
    )
  }

  const isPlpUrl = computed(
    () => route.name === PRODUCT_LIST_ROUTES.PRODUCT_LIST,
  )

  const plpIdInUrl = computed(() => {
    if (isPlpUrl.value) {
      return route.params.uuid as string
    }

    return null
  })

  const masterProductId = computed(() => {
    if (!plpIdInUrl.value) {
      return null
    }

    return getProductId(plpIdInUrl.value) ?? null
  })

  return {
    getProductId,
    getPlpSlug,
    getPlpId,
    plpIdInUrl,
    isPlpUrl,
    masterProductId,
  }
}
